.carousel-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 50px;
    flex-direction: column;
    gap:50px;
    background-color: rgba(0, 0, 0, 0.1);
    /* background-color: white; */
}

.carousel-container h1{
    color: green;
    /* color: white; */
    font-size: 3rem;
    width: 90%;
    text-align: center;
    border-bottom: 3px solid lightgray;
}

.carousel{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    gap: 100px;


}

.carousel img{
    width: 250px;
    height: 300px;
}

.carousel-container button{
    padding: 10px 20px;
    background-color: rgba(0, 128, 0, 0.7);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.carousel-container button:hover{
    background-color: green;
}

.carousel-container p{
    text-align: center;
    padding: 0px 100px;
}

@media screen and (max-width:884px) {
 .carousel{
    gap:40px;
 }   
}

@media screen and (max-width:428px) {
    .carousel-container h1{
       font-size: 2rem;
       padding: 20px 0px;
    }   
    .carousel-container{
        padding: 10px;
        padding: 50px 0px;
    }

    .carousel img{
        width: 70px;
        height: 140px;
    }
    .carousel-container p{
        padding: 0px 10px;
    }
    .carousel-container button{
        /* margin-bottom: 20px; */
    }
   }

   @media screen and (max-width:320px) {
        .carousel{
            gap:20px
        }
   }