.OurStoreWrap2{

}

.store-container2 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center; 
  }
  
.store-container2 h2 {
    margin-bottom: 20px;
    color: green;
    font-size: 3rem;
  }
  
  .product-list2 {
    display: flex;
    flex-wrap: wrap;
    justify-content:center
  }
  
  .product-card2 {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 15px;
    max-width: 250px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
  }

  .product-card2 h3{
    color: green;
  }

 
  
  .product-image2 {
    width: 25%;
    height: 100px;
    border-radius: 8px;
  }

  .product-card:nth-child(3) img, .product-card:nth-child(7) img{
    width: 100%;
    height: 200px;
    border-radius: 8px;
  }
  
  .product-price2 {
    color:green;
    font-weight: bold;
  }

  .product-card2 button{
    background-color: rgba(0, 128, 0, 0.7);
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .product-card2 button:hover{
    background-color: green;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .product-card2 {

    }
  }

  @media screen and (max-width:768px) {
    .OurStoreWrap2{
      padding: 20px 0px;
    }
    .product-card h3{
      font-size: 1rem;
    }
    .product-card2{
      height: 250px;
    }
  }
  
  @media (max-width: 480px) {
    .product-card2 {
      margin: 5px;
      width: 180px;
      height: 250px;
    }

    .product-image2{
      height: 100px;
      width: 60px;
    }
    .product-card2:nth-child(3) img, .product-card:nth-child(7) img{
      height: 110px;
      width: 100px;
    }
    .product-card2 p{
      font-size: 0.8rem;
    }
    .store-container2 h2{
      font-size: 2rem;
    }

    .store-container2{
      padding: 0px;
      padding: 0% 4%;
    }

    
    
  }

 
  
  @media screen and (max-width:390px) {
    .product-card2 h3{
      font-size: 1rem;
    } 
  }

  @media screen and (max-width:375px) {
    .product-card2{
      width: 170px;
    }
  }

  @media screen and (max-width:320px) {
    .product-card2{
      width: 150px;
    } 
  }

  