/* body {
    margin: 0;
    font-family: Arial, sans-serif;
  } */

  .GalleryWrap{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
  }

  .GalleryWrap h1{
    color:green;
    text-align: center;
  }
  
  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
  }
  
  .gallery-item {
    margin: 10px;
    overflow: hidden;
    flex-basis: calc(25% - 20px);
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-item img {
    width: 100%;
    height: 400px;
    display: block;
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-item:hover img {
    transform: scale(1.05);
  }

  /* .gallery-item:nth-child(5) img, .gallery-item:nth-child(6) img{
        height: 450px;
  } */
  
  @media (max-width: 1200px) {
    .gallery-item {
      flex-basis: calc(33.33% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .gallery-item {
      flex-basis: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .gallery-item {
      flex-basis: calc(100% - 20px);
    }
  }
  
  @media screen and (max-width:360px) {
    .gallery-item img{
      height: 350px;
    }
  }

  @media screen and (max-width:320px) {
    .gallery-item img{
      height: 300px;
    }
  }