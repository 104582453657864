.UserSlide{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
    padding: 80px;
    flex-direction: column;
    background-color: gray;
    gap: 20px;
    background-image: url("../Images/65.png");
    background-position: center;
    background-size: cover;
    position: relative;
}

.UserSlide::before{
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
}

.UserSlide img{
    position: relative;
    width: 70%;
    height: 100%;
}

.UserSlide h1{
    position: relative;
    text-align: center;
    color:rgba(255, 255, 255, 0.8);
    font-size: 2.5rem;
}

@media screen and (max-width:884px) {
    .UserSlide{
        height: 550px;
    }
}

@media screen and (max-width:428px) {
    .UserSlide img{
        width: 100%;
    }
    .UserSlide{
        padding: 50px;
        height: 400px;
    }
    .UserSlide h1{
        font-size: 2rem;
    }
    
}

@media screen and (max-width:375px) {
    .UserSlide{
        height: 350px;
        padding: 50px 20px;
    }
    .UserSlide h1{
        font-size: 1.8rem;
    }
}