/* src/OurStore.css */
.OurStoreWrap{
    padding: 100px 0px;
}

.store-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    
  }
  
.store-container h2 {
    margin-bottom: 20px;
    color: green;
    font-size: 3rem;
  }
  
  .product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content:center
  }
  
  .product-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 15px;
    max-width: 250px;
    /* flex: 1 1 calc(33.333% - 20px); */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .product-card h3{
    color: green;
  }

 
  
  .product-image {
    width: 50%;
    height: 200px;
    border-radius: 8px;
  }

  .product-card:nth-child(3) img, .product-card:nth-child(7) img{
    width: 100%;
    height: 200px;
    border-radius: 8px;
  }
  
  .product-price {
    color:green;
    font-weight: bold;
  }

  .product-card button{
    background-color: rgba(0, 128, 0, 0.7);
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .product-card button:hover{
    background-color: green;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .product-card {
      /* flex: 1 1 calc(50% - 20px); */
    }
  }

  @media screen and (max-width:768px) {
    .OurStoreWrap{
      padding: 20px 0px;
    }
    .product-card h3{
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .product-card {
      margin: 5px;
      width: 180px;
    }

    .product-image{
      height: 100px;
      width: 60px;
    }
    .product-card:nth-child(3) img, .product-card:nth-child(7) img{
      height: 110px;
      width: 100px;
    }
    .product-card p{
      font-size: 0.8rem;
    }
    .store-container h2{
      font-size: 2rem;
    }

    .store-container{
      padding: 0px;
    }
  }

 
  
  @media screen and (max-width:390px) {
    .product-card h3{
      font-size: 1rem;
    } 
  }

  @media screen and (max-width:375px) {
    .product-card{
      width: 170px;
    }
  }

  @media screen and (max-width:320px) {
    .product-card{
      width: 250px !important;
    } 
  }

  