/* .Hero{
    display: flex;
    width: 100%;
    height: 500px;
}

.Hero img{
    width: 100%;
    height: 100%;
} */

.Hero {
    max-width: 100%;
    margin: 0 auto;
    padding: 0px 30px;
    margin-top: 70px;
  }

  .carousel .slide img {
    width: 100%;
    height: 450px;
    border-radius: 10px;
  }
  
  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
  
  .carousel .control-arrow:hover {
    opacity: 1;
  }
  
  .carousel .control-dots .dot {
    background-color: white;
    box-shadow: none;
  }
  
  .carousel .control-dots .dot.selected, .carousel .control-dots .dot:focus {
    background-color:white;
    box-shadow: none;
  }
  

  @media screen and (max-width:884px) {
    .Hero{
      height: 400px;
      padding: 0px 15px;
    }
    .carousel .slide img{
      /* margin-top: 50px; */
      height: 451px
    }
    
  }

  @media screen and (max-width:768px) {
    .Hero{
      margin-top: 0px;
    }
  }



  @media screen and (max-width:428px) {
    .Hero{
      height: 170px;
      padding: 0px 5px;
      margin-top: 5px;
    }

    .carousel .slide img{
      /* margin-top: 50px; */
      height: 221px
    }
  }

  @media screen and (max-width:320px) {
    .Hero{
      /* height: 250px; */
    }
    .carousel .slide img{
      /* margin-top: 50px; */
      /* height: 301px */
    }
  }