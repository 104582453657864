
.Header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    padding: 10px 100px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

   
}

.Header img{
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
}

.HeaderRight{
    width: 900px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.Menu{
    text-decoration: none;
    color: green;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 8px 20px;
}

.MenuActive{
    background-color: rgba(0, 128, 0, 0.603);
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 8px 20px;
    border-radius: 10px;
}

.Menu2{
    display: none;
    text-decoration: none;
    color: green;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 8px 20px;
}

.MenuActive2{
    display: none;
    background-color: rgba(0, 128, 0, 0.603);
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    padding: 8px 20px;
    border-radius: 10px;
}

.Burger{
    display: none;
    color: green;
    cursor: pointer;
}

.MobileMenu3{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 60%;
    background-color:rgba(255, 255, 255, 0.9);
    gap: 20px;
    padding: 20px 0px;
}

.Menu3{
    padding: 5px 10px;
 
    font-size: 0.9rem;
    text-decoration: none;
    color: green;
    border-radius: 10px;
    font-weight: 500;
}

.MenuActive3{
    padding: 5px 10px;
    background-color: green;
    font-size: 0.9rem;
    text-decoration: none;
    color: white;
    border-radius: 10px;
}


@media screen and (max-width:884px) {
    .Header{
        padding: 10px 50px;
    }   
    .HeaderRight{
        gap: 5px;
        justify-content: center;
        text-align: center;
    }
    .Menu, .MenuActive{
        padding: 5px 10px;
    }
}


@media screen and (max-width:834px) {
    .Header{
        padding: 10px 20px;
    }   
}





@media screen and (max-width:768px) {
    .Menu, .MenuActive{
        display: none;
    } 
    .Menu2,.MenuActive2{
        display: flex;
    }
    .HeaderRight{
        width: 550px;
        justify-content: space-between;
      
    }
    .Header img{
        width: 40px;
        height: 40px;
        margin-right: 20px;
    }
    .Header{
        top:90vh;
        bottom: 0;
        background-color: white;

    }
    
    
}

@media screen and (max-width:428px){
 .Header img{
    display: none;
 }   
 .Burger{
   
 }
    
}

@media screen and (max-width:375px) {
    .HeaderRight{

    }
    #extraResponse{
        display: none;
    }  
}

@media screen and (max-width:320px) {
    .Header img{
        margin-right: 0px;
        width: 30px;
        height: 30px;
    }
    .HeaderRight{
        width: 285px;
    }
    .Header{
        padding: 7px 5px;
    }
    
}