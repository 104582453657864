*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    
}

.swal2-popup{
  z-index: 9999999999;
}


.swal2-popup .swal2-title {
  font-family: sans-serif;
  color: green;   
  font-size: 1.5rem;
}

.swal2-popup .swal2-text {
  font-family: sans-serif;
  /* color:red; */
}

.SideLogo2{
  width: 100px;
  position: fixed;
  bottom: 25%;
  left: 1%;
  animation: flip 10s infinite;
  z-index: 99;
}

@keyframes flip {
  0% {
      transform: rotateY(0deg);
  }
  100% {
      transform: rotateY(360deg);
  }
}


.WhatsAppIcon{
  position: fixed;
  z-index: 9999;
  bottom: 50px;
  right: 50px;
  width: 70px;
  box-shadow: 0px 4px 5px;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width:768px) {
  .WhatsAppIcon{
      width: 50px;
      bottom: 120px;
      right: 30px;
  }

  
}

@media screen and (max-width:428px) {
  .WhatsAppIcon{
      bottom: 12%;
      right: 5%;
  }
}