/* body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  } */
  
  .LandingPageAboutWrap {
    /* padding-top: 150px; */
    background-color: whitesmoke;
    text-align: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 100px;
    /* width: 100%; */
    
  }
  
  .LandingPageAboutHeader {
    /* background: #ff9800; */
    background-color: rgba(0, 128, 0, 0.822);
    padding: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
  }
  
  .LandingPageAboutLogo {
    height: 50px;
  }
  
  .overview,  .market-presence, .mission-values, .future-outlook {
    margin: 20px 0;
    text-align: center;
    
  }

  .LandingPageAboutProducts{
    margin: 20px 0;
    text-align: left;
  }

  .overview h2{
    color: green;
  }

  .LandingPageAboutProducts h2 {
    font-size: 24px;
    color:green;
  }
  
  .LandingPageAboutProducts {
    display: flex;
    width: 100%;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* margin: 20px 0; */
  }

  .LandingPageAboutProduct{
    display: flex;
    width:100%;
    /* justify-content: center; */
    align-items: center;
    /* background-color: red; */
  }

  .LandingPageAboutProductImageWrap{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: lightgray; */
    padding: 50px;
  }
  
  .LandingPageAboutProductImage {
    width: 20%;
    /* max-width: 100%; */
    height: auto;
  }
  
  .LandingPageAboutProductDetails {
    width: 50%;
    /* text-align: left; */
    max-width: 600px;
    /* margin: 0 auto; */
    /* background-color: blue; */
  }
  
  .LandingPageAboutCarousel-item {
    /* background: #eee; */
    padding: 20px;
    margin: 10px;
    background-color: rgba(0, 128, 0, 0.753);
    color: white;
    /* font-weight: bold; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
  
  .LandingPageAboutFooter {
    background: #333;
    color: white;
    padding: 10px 0;
    cursor: pointer;
    
  }

  .LandingPageAboutFooter p{
    /* animation: scaler 1s infinite; */
  }

  @keyframes scaler {
    0%{transform: scaleX(1.1);}
    25%{transition: scaleX(1.2);}
    50%{transition: scaleX(1.3);}
    75%{transition: scaleX(1.4);}
    100%{transition: scaleX(1.5);}
  }

  .FounderWrap{

  }

  .FounderWrap img{
      width: 15%;
      border-radius: 50%;
  }

  .FounderWrap p{

  }
  
  @media (max-width: 768px) {

    .LandingPageAboutWrap{
      margin-top: 0px;
    }
    .LandingPageAboutProducts{
        text-align: center;
    }
    .LandingPageAboutProduct {
      /* flex-direction: row; */
      flex-direction: column;
    
    }
  
    .LandingPageAboutProductDetails {
      /* margin-left: 20px; */
      width: 100%;
      text-align: center;
    }
    .LandingPageAboutProductImageWrap{
        width: 100%;
    }

    .LandingPageAboutProductImage {
      width: 25%;
      /* max-width: 100%; */
      /* height: auto; */
    }

    .FounderWrap img{
      width: 40%;
    
  }
  }
  