/* src/ContactForm.css */

.ContactFormWrap{

    padding: 100px 0px;
    background-image: url("../Images/HeroPic3.jpeg");
    /* min-height: 100vh; */

    width: 100%;
}


.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
    align-items: center;
    /* max-width: 600px; */
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  }
  
  .contact-form-container h2 {
    text-align: center;
    color: green;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    /* width: 600px; */
    /* background-color: red; */
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: green;
  }
  
  .form-group input,
  .form-group textarea {
    width: 500px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .contact-form-container button {
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 128, 0, 0.7);
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .contact-form-container button:hover {
    background-color: green;
  }

  .contact-form-container img{
    width: 100px;
    display: block;
    border-radius: 50%;
  }


  .FormSubmitUiWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top:0;
    left: 0;
    z-index: 11;
}

.FormSubmitUi{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: auto;
    background-color: whitesmoke;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px ;
    padding: 5%;
    text-align: center;
    font-size: 1.2rem;
}

.FormSubmitUi img{
    width: 20%;
}

.FormSubmitUi p{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width:80%
}

.FormSubmitUi button{
    color: white;
    font-weight: bold;
    background: linear-gradient(to bottom, rgb(179, 179, 231,0.5), rgba(0, 0, 255, 0.7));
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(1);
    transition: 350ms;
    font-size: 1rem;
    padding: 15px;
    margin: 20px;
    /* width: 40%;
    height: 25%; */
}

.FormSubmitUi button:hover{
    transform: scale(1.1);
}

.FormSubmitUi button:active{
    transform: scale(0.9);
}

  
  /* Responsive Styles */

@media screen and (max-width:884px) {
  .ContactFormWrap{
    padding: 200px 0px;
  }
}

  @media (max-width: 600px) {
    .contact-form-container {
      padding: 10px;
    }
  
  .contact-form-container button {
      font-size: 14px;
    }
  }

  @media screen and (max-width:428px) {
    .ContactFormWrap{
      padding: 20px 10px;
    } 
    .contact-form-container{
      width: 100%;

    }
    .form-group input,
  .form-group textarea {
   width: 300px;
  }

  .FormSubmitUi{
    width: 90%;
    font-size: 1.3rem;
}
.FormSubmitUi button{ 
    font-size: 1.2rem;
}
}

    
 

  @media screen and (max-width:360px) {
   
    .form-group input,
  .form-group textarea {
   width: 250px;
  }
    
  }
  