.SpinningBottles{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 200px;
    padding: 50px;
    background-image: url("../Images/logo1.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    height: 250px;
}

.SpinningBottles::before{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* background-color: rgba(0, 0, 0, 0.2); */
}

.SpinningBottles img{
    width: 100px;
    height: 200px;
    animation: flip 3s infinite;
}

.SpinningBottles h1{
    color: green;
    color: white;
    font-size: 2rem;
    text-align: center;
    animation: Resize 5s infinite;
    position: relative;
}

@keyframes flip {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

@keyframes Resize {
    0%{ transform: scale(1.1);}
    25%{transform: scale(1.2);}
    50%{transform: scale(1.3);}
    75%{transform: scale(1.4);}
    100%{transform: scale(1.5);}
}

@media screen and (max-width:428px){
    .SpinningBottles{
        height: 300px;
        gap:50px;
    }   

    .SpinningBottles h1{
        font-size: 1.2rem;
    }
    .SpinningBottles img{
        width: 80px;
        height: 160px;
    }
}

@media screen and (max-width:375px){
    .SpinningBottles{
   /* gap:50px; */
    }   

   
}

