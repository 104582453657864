.AboutUs{
    display: flex;
    width: 100%;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    padding: 90px;
    min-height: 700px;
    
}

.AboutUs h1{
    color: green;
    width: 100%;
    background-color: rgba(211, 211, 211, 0.2);
    padding: 5px 10px;
    text-align: center;
    border-bottom: 1px solid lightgray;
}
.AboutUsBody{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: auto;
    /* padding-top:20px ; */
    background-color: rgba(211, 211, 211, 0.2);
    /* padding-bottom: 20px; */
}
.AboutUsSideBar{
    width: 20%;
    display: flex;
    /* background-color: rgba(211, 211, 211, 0.5); */
    flex-direction: column;
    gap: 1px;
    /* padding: 0px 10px; */
}


.AboutUsSideBarMenu{
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 5px;
    /* border: 1px solid green; */
    text-align: center;
    color: green;
    background-color: rgba(211, 211, 211, 0.5);
}

.AboutUsSideBarMenuActive{
    padding: 10px 10px;
    cursor: pointer;
    /* border-radius: 10px; */
    /* border: 1px solid green; */
    text-align: center;
    color: green;
    background-color: white;
}

.AboutUsContent{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.ContentA{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: white;
}

.ContentA h2{
    text-align: center;
    color: green;
}

.ContentA p{
    text-align: center;
    color: gray;
}

.ContentA img{
    width: 200px;
    height: 150px;
    text-align: center;
}

.ContentB{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    gap: 30px;
    background-color: white;
    width: 100%;
    
}

.ContentB{
    color: green;
}

.ContentB1{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    width: 100%;
}

.ContentB1 img{
    border-radius: 50%;
}

@media screen and (max-width:884px) {
    .ContentB1{
        flex-direction: column-reverse;
    }

    .ContentB1 img{
        width: 200px;
    }
}

@media screen and (max-width:768px) {
    .AboutUs{
        padding: 0px;
        padding:0px 0px
    } 
    .AboutUsSideBarMenu{
        font-size: 0.8rem;
    }  
    .AboutUsSideBarMenuActive{
        font-size: 0.8rem;
    } 
    .AboutUs h1{
        font-size: 1.5rem;
        padding: 20px;
    }
    .ContentA h2{
        font-size: 1.2rem;
    }
}

@media screen and (max-width:428px) {
    .AboutUsSideBarMenu{
        font-size: 0.6rem;
    }  
    .AboutUsSideBarMenuActive{
        font-size: 0.6rem;
    }
    .ContentB{
        padding: 8px;
        padding-bottom: 50px;
    }
}

@media screen and (max-width:320px) {
   
    .AboutUsSideBarMenu{
        font-size: 0.5rem;
    }  
    .AboutUsSideBarMenuActive{
        font-size: 0.5rem;
    } 
}

