.BlogDetailWrap{
    display: flex;
    padding: 100px;
    justify-content: center;
    align-items: center;
}

.BlogDetail {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .BlogDetail img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
  }
  
  .BlogDetail h1 {
    font-size: 2em;
    margin: 20px 0;
    color: green;
  }
  
  .BlogDetail p {
    /* font-size: 1.2em; */
    line-height: 1.6;
    text-align: center;
  }
  
  .BackToBlogs {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background:rgba(0, 128, 0, 0.6);
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .BackToBlogs:hover {
    background:green;
  }
  
  /* @media (max-width: 768px) {
    .BlogDetail {
      padding: 15px;
    }
  
    .BlogDetail h1 {
      font-size: 1.5em;
    }
  
    .BlogDetail p {
      font-size: 1em;
    }
  } */
  
  @media (max-width: 480px) {
    .BlogDetail {
      padding: 10px;
    }
  
    .BlogDetail h1 {
      font-size: 1.2em;
    }
  
    .BlogDetail p {
      font-size: 0.9em;
    }
    .BlogDetailWrap{
      padding: 0px;
      padding: 100px 5px;
    }
  }
  

  @media screen and (max-width:768px) {
    .BlogDetailWrap{
      padding: 0px 5px;
    }
  }