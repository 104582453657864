.BlogsWrap{
    
    background-color:whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
    gap: 30px;
}

.BlogsWrap h1{
    color: green;
  
    font-size: 2rem;
    text-align: center;
    
}

.Blogs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  
    
   
  }
  
  .BlogCard {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 350px;
    text-align: center;
    transition: transform 0.3s ease;
    
  }

  .BlogCard h2{
    color: green;
  }
  
  .BlogCard:hover {
    transform: translateY(-5px);
  }
  
  .BlogCard img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .BlogCard h2 {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .BlogCard p {
    font-size: 0.9em;
    padding: 0 10px;
    color: #555;
  }
  
  .BlogCard .ReadMore {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    background:rgba(0, 128, 0, 0.7);
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .BlogCard .ReadMore:hover {
    background: green;
  }

  @media screen and (max-width:884px) {
    .Blogs{
      gap:40px;

    }
    .BlogsWrap{
      padding: 30px;
    }
  }

  @media (max-width: 768px) {
    .Blogs {
      gap: 30px;
      padding: 10px;
    }

    .BlogCard{
      max-width: 300px;
    }
  
    .BlogCard h2 {
      font-size: 1.1em;
    }
  
    .BlogCard p {
      font-size: 0.85em;
    }
  }
  
  @media (max-width: 480px) {
    .Blogs {
      gap: 10px;
      padding: 10px;
    }
  
    .BlogCard h2 {
      font-size: 1em;
    }
  
    .BlogCard p {
      font-size: 0.8em;
    }
  
    .BlogCard .ReadMore {
      padding: 8px 15px;
    }
  }

  @media screen and (max-width:428px) {
    .BlogsWrap h1{
      font-size: 1.8rem;
    
    }  

    .BlogCard:nth-child(3){
      display: none;
    }

    .BlogCard{
      width: 180px;
    }
    .Blogs{
      padding: 0px;
    }
    .BlogsWrap{
      padding: 0px;
      padding: 20px 0px;
    }
    .BlogCard img{
      height: 150px;
    }

    .BlogCard h2{
      padding: 5px;
    }
  }
  
@media screen and (max-width:375px) {
  .Blogs{
    gap:0px;
  }
  .BlogCard{
    margin: 5px;
    width: 175px;
  }
}

@media screen and (max-width:360px) {
  .Blogs{
    gap:0px;
  }
  .BlogCard{
    width: 300px;
  }
}